import React, { useContext, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AppContext } from '../App';
import StoreCard from './StoreCard';

const MallInfo = () => {
  const { cityName, mallName } = useParams();
  const { data } = useContext(AppContext);

  const mallData = useMemo(() => {
    return data[cityName]?.[mallName] || null;
  }, [data, cityName, mallName]);

  if (!mallData) {
    return <div>Mall not found</div>;
  }

  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">{mallName}</h1>
      <p className="mb-4">City: {cityName}</p>
      {mallData.location && (
        <p className="mb-4">
          Location: {mallData.location.lat}, {mallData.location.lng}
        </p>
      )}
      <h2 className="text-2xl font-bold mb-2">Stores</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {mallData.stores.map((store, index) => (
          <StoreCard key={index} store={store} mallName={mallName} />
        ))}
      </div>
      <Link to="/map" className="mt-4 inline-block text-blue-600 hover:underline">
        Back to Map
      </Link>
    </div>
  );
};

export default MallInfo;