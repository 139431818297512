import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App'; // Adjust this import path as necessary

const MapComponent = () => {
  const mapRef = useRef(null);
  const [error, setError] = useState(null);
  const { data } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadGoogleMapsApi = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      window.initMap = () => {
        try {
          const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 1.3521, lng: 103.8198 },  // Singapore coordinates
            zoom: 11
          });

          Object.entries(data).forEach(([cityName, cityMalls]) => {
            Object.entries(cityMalls).forEach(([mallName, mallData]) => {
              if (mallData.location && mallData.location.lat && mallData.location.lng) {
                const marker = new window.google.maps.Marker({
                  position: { lat: mallData.location.lat, lng: mallData.location.lng },
                  map: map,
                  title: mallName
                });

                const infoWindow = new window.google.maps.InfoWindow({
                  content: `
                    <h3>${mallName}</h3>
                    <br>
                    <button onclick="window.navigateToMall('${cityName}', '${mallName}')">
                      View Stores
                    </button>
                  `
                });

                marker.addListener('click', () => {
                  infoWindow.open(map, marker);
                });
              }
            });
          });

          // Add a global function to handle navigation
          window.navigateToMall = (cityName, mallName) => {
            navigate(`/${encodeURIComponent(cityName)}/${encodeURIComponent(mallName)}`);
          };

        } catch (err) {
          console.error('Error initializing map:', err);
          setError('Failed to initialize the map. Please check the console for more details.');
        }
      };

      script.onerror = () => {
        console.error('Failed to load Google Maps API script');
        setError('Failed to load Google Maps. Please check your internet connection and try again.');
      };
    };

    loadGoogleMapsApi();
  }, [data, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div ref={mapRef} style={{ height: '500px', width: '100%' }}></div>;
};

export default MapComponent;