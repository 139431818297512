import ReactGA from 'react-ga4';
ReactGA.initialize('G-PDNRQWDHMQ');

const StoreCard = ({ store, mallName }) => {
    const cardContent = (
      <>
        <div className="h-48 bg-gray-200 flex items-center justify-center">
          {store.logo_url ? (
            <img 
              src={store.logo_url} 
              alt={`${store.name} logo`} 
              className="object-cover h-full w-full"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://via.placeholder.com/150?text=No+Image';
              }}
            />
          ) : (
            <span className="text-gray-500">No logo available</span>
          )}
        </div>
        <div className="p-4">
        {store.name}{' '}<span className="text-blue-600">@ {mallName}</span>
          <p className="text-gray-600">{store.location}</p>
          {/* <p className="text-gray-600">{mallName}</p> */}
          <p className="mt-2 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
            {store.category}
          </p>
          {store.zone && <p className="text-sm text-gray-500 mt-1">{store.zone}</p>}
        </div>
      </>
    );

    const handleStoreClick = () => {
        ReactGA.event({
          category: 'User',
          action: 'Clicked Store',
          label: store.name
        });
      };
  
    return store.link ? (
    <a 
        href={store.link} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="bg-white shadow rounded-lg overflow-hidden block transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        onClick={handleStoreClick}
    >
        {cardContent}
    </a>
    ) : (
    <div className="bg-white shadow rounded-lg overflow-hidden">
        {cardContent}
    </div>
    );
};

export default StoreCard