import React, { useState, useEffect, createContext, useContext } from 'react';
import ReactGA from 'react-ga4';
import Select from './components/Select';
import Input from './components/Input';
import AboutUs from './components/AboutUs';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import StoreCard from './components/StoreCard';
import BasicMapComponent from './components/BasicMapComponent';
import MallInfo from './components/Mallinfo';
// import Banner from './components/Banner';
// Initialize GA4
ReactGA.initialize('G-PDNRQWDHMQ');

// Create a context for our app state
export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [filteredStores, setFilteredStores] = useState([]);
  const [cities, setCities] = useState([]);
  const [malls, setMalls] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedMall, setSelectedMall] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetch('/city_malls_stores.json')
      .then(response => response.json())
      .then(jsonData => {
        setData(jsonData);
        setCities(Object.keys(jsonData));
        
        const allCategories = new Set();
        Object.values(jsonData).forEach(cityMalls => {
          Object.values(cityMalls).forEach(mallData => {
            mallData.stores.forEach(store => allCategories.add(store.category));
          });
        });
        setCategories(Array.from(allCategories));

        // Track page view
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      });
  }, []);

  useEffect(() => {
    if (selectedCity) {
      setMalls(Object.keys(data[selectedCity] || {}));
    } else {
      setMalls([]);
    }
  }, [selectedCity, data]);

  useEffect(() => {
    let filtered = [];
    if (selectedCity && data[selectedCity]) {
      if (selectedMall && data[selectedCity][selectedMall]) {
        filtered = data[selectedCity][selectedMall].stores.map(store => ({
          ...store,
          mallName: selectedMall
        }));
      } else {
        filtered = Object.entries(data[selectedCity]).flatMap(([mallName, mallData]) => 
          mallData.stores.map(store => ({
            ...store,
            mallName
          }))
        );
      }
    } else {
      filtered = Object.entries(data).flatMap(([cityName, cityMalls]) => 
        Object.entries(cityMalls).flatMap(([mallName, mallData]) => 
          mallData.stores.map(store => ({
            ...store,
            mallName,
            cityName
          }))
        )
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(store => store.category === selectedCategory);
    }

    if (searchTerm) {
      filtered = filtered.filter(store => 
        store.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        store.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (store.location && store.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
        store.mallName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredStores(filtered);
  }, [selectedCity, selectedMall, selectedCategory, searchTerm, data]);

  const handleSelectChange = (name, value) => {
    switch(name) {
      case 'city':
        setSelectedCity(value);
        setSelectedMall('');
        ReactGA.event({
          category: 'User',
          action: 'Selected City',
          label: value
        });
        break;
      case 'mall':
        setSelectedMall(value);
        ReactGA.event({
          category: 'User',
          action: 'Selected Mall',
          label: value
        });
        break;
      case 'category':
        setSelectedCategory(value);
        ReactGA.event({
          category: 'User',
          action: 'Selected Category',
          label: value
        });
        break;
      default:
        console.warn(`Unexpected select name: ${name}`);
        ReactGA.event({
          category: 'Error',
          action: 'Unexpected Select Change',
          label: `${name}: ${value}`
        });
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    ReactGA.event({
      category: 'User',
      action: 'Searched',
      label: value
    });
  };

  return (
    <AppContext.Provider value={{
      data, filteredStores, cities, malls, categories,
      selectedCity, selectedMall, selectedCategory, searchTerm,
      handleSelectChange, handleSearch
    }}>
      {children}
    </AppContext.Provider>
  );
};

const FilterControls = () => {
  const { 
    cities, malls, categories, 
    selectedCity, selectedMall, selectedCategory, searchTerm,
    handleSelectChange, handleSearch 
  } = useContext(AppContext);

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
      <Select
        value={selectedCity}
        onChange={e => handleSelectChange('city', e.target.value)}
        placeholder="Select City"
      >
        <option value="">All Cities</option>
        {cities.map(city => (
          <option key={city} value={city}>{city}</option>
        ))}
      </Select>
      <Select
        value={selectedMall}
        onChange={e => handleSelectChange('mall', e.target.value)}
        placeholder="Select Mall"
        disabled={!selectedCity}
      >
        <option value="">All Malls</option>
        {malls.map(mall => (
          <option key={mall} value={mall}>{mall}</option>
        ))}
      </Select>
      <Select
        value={selectedCategory}
        onChange={e => handleSelectChange('category', e.target.value)}
        placeholder="Select Category"
      >
        <option value="">All Categories</option>
        {categories.map(category => (
          <option key={category} value={category}>{category}</option>
        ))}
      </Select>
      <Input
        type="text"
        placeholder="Search stores..."
        value={searchTerm}
        onChange={e => handleSearch(e.target.value)}
      />
    </div>
  );
};

const Banner = () => (
  <div className="w-full mb-6">
    <img 
      src="/images/banner.webp" 
      alt="Mall Directory Banner" 
      className="w-full h-auto object-cover"
      style={{ maxHeight: '200px' }} // Adjust this value as needed
    />
  </div>
);

const MainContent = () => {
  const { filteredStores } = useContext(AppContext);

  // const { handleSelectChange } = useContext(AppContext);
  
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const city = urlParams.get('city');
  //   const mall = urlParams.get('mall');
    
  //   if (city) handleSelectChange('city', city);
  //   if (mall) handleSelectChange('mall', mall);
  // }, []);

  return (
    <>
      <Banner />
      <FilterControls />
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredStores.map((store, index) => (
          <StoreCard key={index} store={store} mallName={store.mallName} />
        ))}
      </div>
    </>
  );
};

const App = () => {
  return (
    <AppProvider>
      <Router>
        <div className="container mx-auto p-4">
          <nav className="mb-4">
            <ul className="flex space-x-4">
              <li>
                <Link to="/" className="text-blue-600 hover:underline">Home</Link>
              </li>
              <li>
                <Link to="/map" className="text-blue-600 hover:underline">Map View</Link>
              </li>
              <li>
                <Link to="/about" className="text-blue-600 hover:underline">About Us</Link>
              </li>
            </ul>
          </nav>
          <Routes>
            <Route path="/" element={
              <>
                <h1 className="text-3xl font-bold mb-4">Shopping Mall Directory</h1>
                <MainContent />
              </>
            } />
            <Route path="/map" element={<BasicMapComponent />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/:cityName/:mallName" element={<MallInfo />} />
          </Routes>
        </div>
      </Router>
    </AppProvider>
  );
};

export default App;
