import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="mb-4">
        Welcome to Mall Directories, your comprehensive guide to shopping malls and stores across various cities. 
        Our mission is to provide an easy-to-use platform for shoppers to discover and explore retail options in their area.
      </p>
      <p className="mb-4">
        We strive to keep our directory up-to-date with the latest information on stores, categories, and locations. 
        Our user-friendly interface allows you to quickly find the stores you're looking for, whether you're planning a 
        shopping trip or just curious about what's available in your local malls.
      </p>
      <p className="mb-4">
        If you have any questions, suggestions, or feedback, we'd love to hear from you. Please don't hesitate to reach out to us at:
      </p>
      <p className="font-semibold">
        <a href="mailto:hello@malldirectories.com" className="text-blue-600 hover:underline">hello@malldirectories.com</a>
      </p>
    </div>
  );
};

export default AboutUs;